
import { defineComponent } from "vue";
import UiSkeletonSubtitle from "./UiSkeletonSubtitle.vue";
import PCard from "@/components/PCard.vue";
import UiSkeletonText from "@/components/ui/skeletons/UiSkeletonText.vue";
import UiSkeletonTitle from "@/components/ui/skeletons/UiSkeletonTitle.vue";

export default defineComponent({
  name: "UiSkeletonTable",
  components: { UiSkeletonTitle, UiSkeletonText, PCard, UiSkeletonSubtitle }
});
