
import { defineComponent, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import { useTimesheet } from "@/store/timesheet.store";
import { dateToString } from "@/utils";

import PCalendar from "@/components/calendar/PCalendar.vue";
import PHeader from "@/components/PHeader.vue";
import PMain from "@/components/PMain.vue";
import PGrid from "@/components/PGrid.vue";
import PFooter from "@/components/PFooter.vue";
import PVisit from "@/components/PVisit.vue";
import PMonthPicker from "@/components/PMonthPicker.vue";
import UiSkeletonTable from "@/components/ui/skeletons/UiSkeletonTable.vue";
import UiSkeletonCalendar from "@/components/ui/skeletons/UiSkeletonCalendar.vue";
import { useAttendancePage } from "@/composable/useAttendancePage";

export default defineComponent({
  name: "Attendance",
  components: {
    UiSkeletonCalendar,
    UiSkeletonTable,
    PMonthPicker,
    PCalendar,
    PHeader,
    PMain,
    PVisit,
    PGrid,
    PFooter
  },

  setup() {
    const attendance = useAttendancePage();

    return { date: attendance.date, route: attendance.route };
  }
});
