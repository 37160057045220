export function dateToString(date: Date): string {
  return date
    .toLocaleDateString("ru-RU")
    .split(".")
    .reverse()
    .join("-");
}

export function formatDateString(dateString: string): string {
  if (!dateString) return "---";
  return new Date(dateString).toLocaleDateString("ru-RU", {
    day: "numeric",
    month: "short",
    year: "numeric"
  });
}

export function formatTimeString(dateString: string): string {
  if (!dateString) return "---";
  const date = new Date(dateString);

  return date.toLocaleTimeString("ru-RU").slice(0, 5);

  // return dateString.split("T")[1].replace(":00.000+0000", "");
}

export function sameDate(source: Date, target: string) {
  const date = source
    .toLocaleDateString("ru-RU")
    .split(".")
    .reverse()
    .join("-");

  return date === target;
}

export const weekdays = ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"];
export const months = [
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь"
];
