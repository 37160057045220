
import { computed, defineComponent, PropType, ref } from "vue";

import { months } from "@/utils";

export default defineComponent({
  name: "PMonthPicker",
  emits: ["update:date"],

  props: {
    date: { type: Object as PropType<Date>, default: () => new Date() }
  },

  setup(props, { emit }) {
    const today = ref<Date>(new Date());

    const currentMonth = ref(props.date.getMonth());
    const currentYear = ref(props.date.getFullYear());

    const screen = ref("empty");

    const years = computed(() => {
      const range = [];

      for (let year = 2021 - 4; year <= 2021 + 4; year++) {
        range.push(year);
      }

      return range;
    });

    function emitDate() {
      emit("update:date", new Date(currentYear.value, currentMonth.value, 1));
    }

    function disabledYear(year: number) {
      return today.value.getFullYear() < year;
    }

    function disabledMonth(month: number) {
      return (
        currentYear.value >= today.value.getFullYear() &&
        month > today.value.getMonth()
      );
    }

    function setMonth(month: number) {
      currentMonth.value = month;
      screen.value = "empty";
      emitDate();
    }

    function setYear(year: number) {
      if (currentMonth.value > today.value.getMonth()) {
        currentMonth.value = today.value.getMonth();
      }

      currentYear.value = year;
      screen.value = "empty";
      emitDate();
    }

    return {
      currentMonth,
      currentYear,
      months,
      screen,
      years,
      setYear,
      setMonth,
      disabledYear,
      disabledMonth
    };
  }
});
